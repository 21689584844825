var exports = {};

var EventEmitter =
/** @class */
function () {
  function EventEmitter() {
    this._events = {};
  }

  EventEmitter.prototype.on = function (event, callback) {
    if (this._events[event]) {
      this._events[event].push(callback);
    } else {
      this._events[event] = [callback];
    }
  };

  EventEmitter.prototype.off = function (event, callback) {
    var eventCallbacks = this._events[event];

    if (!eventCallbacks) {
      return;
    }

    var indexOfCallback = eventCallbacks.indexOf(callback);
    eventCallbacks.splice(indexOfCallback, 1);
  };

  EventEmitter.prototype._emit = function (event) {
    var args = [];

    for (var _i = 1; _i < arguments.length; _i++) {
      args[_i - 1] = arguments[_i];
    }

    var eventCallbacks = this._events[event];

    if (!eventCallbacks) {
      return;
    }

    eventCallbacks.forEach(function (callback) {
      callback.apply(void 0, args);
    });
  };

  EventEmitter.prototype.hasListener = function (event) {
    var eventCallbacks = this._events[event];

    if (!eventCallbacks) {
      return false;
    }

    return eventCallbacks.length > 0;
  };

  EventEmitter.createChild = function (ChildObject) {
    ChildObject.prototype = Object.create(EventEmitter.prototype, {
      constructor: ChildObject
    });
  };

  return EventEmitter;
}();

exports = EventEmitter;
export default exports;